@file:OptIn(org.jetbrains.compose.resources.InternalResourceApi::class)

package ailingo.composeapp.generated.resources

import kotlin.OptIn
import kotlin.String
import kotlin.collections.MutableMap
import org.jetbrains.compose.resources.DrawableResource
import org.jetbrains.compose.resources.InternalResourceApi

private object CommonMainDrawable0 {
  public val ArrowBackIOS: DrawableResource by 
      lazy { init_ArrowBackIOS() }

  public val ArrowForwardIOS: DrawableResource by 
      lazy { init_ArrowForwardIOS() }

  public val LandgingPhone: DrawableResource by 
      lazy { init_LandgingPhone() }

  public val ailingologowithoutbackground: DrawableResource by 
      lazy { init_ailingologowithoutbackground() }

  public val backHeader: DrawableResource by 
      lazy { init_backHeader() }

  public val business: DrawableResource by 
      lazy { init_business() }

  public val cartoon: DrawableResource by 
      lazy { init_cartoon() }

  public val coins: DrawableResource by 
      lazy { init_coins() }

  public val culture: DrawableResource by 
      lazy { init_culture() }

  public val defaultProfilePhoto: DrawableResource by 
      lazy { init_defaultProfilePhoto() }

  public val fashion: DrawableResource by 
      lazy { init_fashion() }

  public val film: DrawableResource by 
      lazy { init_film() }

  public val food: DrawableResource by 
      lazy { init_food() }

  public val googlePlay: DrawableResource by 
      lazy { init_googlePlay() }

  public val health: DrawableResource by 
      lazy { init_health() }

  public val history: DrawableResource by 
      lazy { init_history() }

  public val literature: DrawableResource by 
      lazy { init_literature() }

  public val logo: DrawableResource by 
      lazy { init_logo() }

  public val nature: DrawableResource by 
      lazy { init_nature() }

  public val news: DrawableResource by 
      lazy { init_news() }

  public val science: DrawableResource by 
      lazy { init_science() }

  public val sport: DrawableResource by 
      lazy { init_sport() }

  public val streak: DrawableResource by 
      lazy { init_streak() }

  public val technology: DrawableResource by 
      lazy { init_technology() }

  public val trips: DrawableResource by 
      lazy { init_trips() }
}

@InternalResourceApi
internal fun _collectCommonMainDrawable0Resources(map: MutableMap<String, DrawableResource>) {
  map.put("ArrowBackIOS", CommonMainDrawable0.ArrowBackIOS)
  map.put("ArrowForwardIOS", CommonMainDrawable0.ArrowForwardIOS)
  map.put("LandgingPhone", CommonMainDrawable0.LandgingPhone)
  map.put("ailingologowithoutbackground", CommonMainDrawable0.ailingologowithoutbackground)
  map.put("backHeader", CommonMainDrawable0.backHeader)
  map.put("business", CommonMainDrawable0.business)
  map.put("cartoon", CommonMainDrawable0.cartoon)
  map.put("coins", CommonMainDrawable0.coins)
  map.put("culture", CommonMainDrawable0.culture)
  map.put("defaultProfilePhoto", CommonMainDrawable0.defaultProfilePhoto)
  map.put("fashion", CommonMainDrawable0.fashion)
  map.put("film", CommonMainDrawable0.film)
  map.put("food", CommonMainDrawable0.food)
  map.put("googlePlay", CommonMainDrawable0.googlePlay)
  map.put("health", CommonMainDrawable0.health)
  map.put("history", CommonMainDrawable0.history)
  map.put("literature", CommonMainDrawable0.literature)
  map.put("logo", CommonMainDrawable0.logo)
  map.put("nature", CommonMainDrawable0.nature)
  map.put("news", CommonMainDrawable0.news)
  map.put("science", CommonMainDrawable0.science)
  map.put("sport", CommonMainDrawable0.sport)
  map.put("streak", CommonMainDrawable0.streak)
  map.put("technology", CommonMainDrawable0.technology)
  map.put("trips", CommonMainDrawable0.trips)
}

internal val Res.drawable.ArrowBackIOS: DrawableResource
  get() = CommonMainDrawable0.ArrowBackIOS

private fun init_ArrowBackIOS(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:ArrowBackIOS",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/ailingo.composeapp.generated.resources/drawable/ArrowBackIOS.png", -1, -1),
    )
)

internal val Res.drawable.ArrowForwardIOS: DrawableResource
  get() = CommonMainDrawable0.ArrowForwardIOS

private fun init_ArrowForwardIOS(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:ArrowForwardIOS",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/ailingo.composeapp.generated.resources/drawable/ArrowForwardIOS.png", -1, -1),
    )
)

internal val Res.drawable.LandgingPhone: DrawableResource
  get() = CommonMainDrawable0.LandgingPhone

private fun init_LandgingPhone(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:LandgingPhone",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/ailingo.composeapp.generated.resources/drawable/LandgingPhone.png", -1, -1),
    )
)

internal val Res.drawable.ailingologowithoutbackground: DrawableResource
  get() = CommonMainDrawable0.ailingologowithoutbackground

private fun init_ailingologowithoutbackground(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:ailingologowithoutbackground",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/ailingo.composeapp.generated.resources/drawable/ailingologowithoutbackground.png", -1, -1),
    )
)

internal val Res.drawable.backHeader: DrawableResource
  get() = CommonMainDrawable0.backHeader

private fun init_backHeader(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:backHeader",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/ailingo.composeapp.generated.resources/drawable/backHeader.png", -1, -1),
    )
)

internal val Res.drawable.business: DrawableResource
  get() = CommonMainDrawable0.business

private fun init_business(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:business",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/ailingo.composeapp.generated.resources/drawable/business.png", -1, -1),
    )
)

internal val Res.drawable.cartoon: DrawableResource
  get() = CommonMainDrawable0.cartoon

private fun init_cartoon(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:cartoon",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/ailingo.composeapp.generated.resources/drawable/cartoon.png", -1, -1),
    )
)

internal val Res.drawable.coins: DrawableResource
  get() = CommonMainDrawable0.coins

private fun init_coins(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:coins",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/ailingo.composeapp.generated.resources/drawable/coins.png", -1, -1),
    )
)

internal val Res.drawable.culture: DrawableResource
  get() = CommonMainDrawable0.culture

private fun init_culture(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:culture",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/ailingo.composeapp.generated.resources/drawable/culture.png", -1, -1),
    )
)

internal val Res.drawable.defaultProfilePhoto: DrawableResource
  get() = CommonMainDrawable0.defaultProfilePhoto

private fun init_defaultProfilePhoto(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:defaultProfilePhoto",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/ailingo.composeapp.generated.resources/drawable/defaultProfilePhoto.png", -1, -1),
    )
)

internal val Res.drawable.fashion: DrawableResource
  get() = CommonMainDrawable0.fashion

private fun init_fashion(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:fashion",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/ailingo.composeapp.generated.resources/drawable/fashion.png", -1, -1),
    )
)

internal val Res.drawable.film: DrawableResource
  get() = CommonMainDrawable0.film

private fun init_film(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:film",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/ailingo.composeapp.generated.resources/drawable/film.png", -1, -1),
    )
)

internal val Res.drawable.food: DrawableResource
  get() = CommonMainDrawable0.food

private fun init_food(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:food",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/ailingo.composeapp.generated.resources/drawable/food.png", -1, -1),
    )
)

internal val Res.drawable.googlePlay: DrawableResource
  get() = CommonMainDrawable0.googlePlay

private fun init_googlePlay(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:googlePlay",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/ailingo.composeapp.generated.resources/drawable/googlePlay.png", -1, -1),
    )
)

internal val Res.drawable.health: DrawableResource
  get() = CommonMainDrawable0.health

private fun init_health(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:health",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/ailingo.composeapp.generated.resources/drawable/health.png", -1, -1),
    )
)

internal val Res.drawable.history: DrawableResource
  get() = CommonMainDrawable0.history

private fun init_history(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:history",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/ailingo.composeapp.generated.resources/drawable/history.png", -1, -1),
    )
)

internal val Res.drawable.literature: DrawableResource
  get() = CommonMainDrawable0.literature

private fun init_literature(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:literature",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/ailingo.composeapp.generated.resources/drawable/literature.png", -1, -1),
    )
)

internal val Res.drawable.logo: DrawableResource
  get() = CommonMainDrawable0.logo

private fun init_logo(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:logo",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/ailingo.composeapp.generated.resources/drawable/logo.png", -1, -1),
    )
)

internal val Res.drawable.nature: DrawableResource
  get() = CommonMainDrawable0.nature

private fun init_nature(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:nature",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/ailingo.composeapp.generated.resources/drawable/nature.png", -1, -1),
    )
)

internal val Res.drawable.news: DrawableResource
  get() = CommonMainDrawable0.news

private fun init_news(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:news",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/ailingo.composeapp.generated.resources/drawable/news.png", -1, -1),
    )
)

internal val Res.drawable.science: DrawableResource
  get() = CommonMainDrawable0.science

private fun init_science(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:science",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/ailingo.composeapp.generated.resources/drawable/science.png", -1, -1),
    )
)

internal val Res.drawable.sport: DrawableResource
  get() = CommonMainDrawable0.sport

private fun init_sport(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:sport",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/ailingo.composeapp.generated.resources/drawable/sport.png", -1, -1),
    )
)

internal val Res.drawable.streak: DrawableResource
  get() = CommonMainDrawable0.streak

private fun init_streak(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:streak",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/ailingo.composeapp.generated.resources/drawable/streak.png", -1, -1),
    )
)

internal val Res.drawable.technology: DrawableResource
  get() = CommonMainDrawable0.technology

private fun init_technology(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:technology",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/ailingo.composeapp.generated.resources/drawable/technology.png", -1, -1),
    )
)

internal val Res.drawable.trips: DrawableResource
  get() = CommonMainDrawable0.trips

private fun init_trips(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:trips",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/ailingo.composeapp.generated.resources/drawable/trips.png", -1, -1),
    )
)
